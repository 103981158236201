.icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #FFCC44;
    padding: 8px;
}

.table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
}

.contactIcon {
    font-size: 26px !important;
    margin: 5px;
}

.contactIcontiktok {
    font-size: 15px !important;
    margin: 5px;
    padding: 2px 4px;
    background-color: black;
    border-radius: 100%;
    color: white;
}

.contactInfo {
    font-weight: 460;
    color: black;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.contactInfo:hover {
    cursor: pointer;
    color: #FFCC44;
}

.card {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.arrow {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.arrowBack {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

.displayInfo {
    display: block;
}

.hideInfo {
    display: none;
}

.clickzone:hover {
    cursor: pointer;
}

.button {
    outline: none !important;
    border: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}