.card {
    min-height: 300px !important;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.myBtn {
    background-color: #FFCC44;
    color: #000000;
    border-radius: 20px;
    padding: 9px 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #FFCC44;
    font-size: 15px;
    font-weight: 500;
}

.myBtn:hover {
    background-color: transparent;
    color: #FFCC44;
}

.Btn {
    background-color: #000000;
    color: #ffffff;
    border-radius: 20px;
    padding: 9px 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #000000;
    font-size: 15px;
    font-weight: 500;
}

.Btn:hover {
    background-color: transparent;
    color: #000000;
}

.formCheckInput:checked {
    box-shadow: none !important;
    border: 0 solid;
    background-color: #ffcc44 !important;
}

.formCheckInput:not(:checked) {
    box-shadow: none !important;
    border: 0 solid;
    background-color: #faecc5 !important;
}

.loader {
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
    bottom: 0%;
    margin-left: 7px;
    margin-bottom: 4rem;
  }
  
  .loader::after,
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #ffcc44;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
  }
  
  .loader::after {
    animation-delay: 0s;
  }
  
  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  