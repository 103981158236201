 .btn {
   background-color: #ffcc44;
   color: #ffffff;
   border-radius: 20px;
   width: 80%;
   padding: 9px 25px;
   cursor: pointer;
   transition: background-color 0.3s ease, color 0.3s ease;
   border: 2px solid #ffcc44;
   font-size: 15px;
   font-weight: 500;
   transition: 0.35s;
 }

 .btn:hover {
   background-color: transparent;
   color: #ffcc44;
 }

 .customBtn {
   display: inline-block;
   background: white;
   color: #444;
   width: 80% !important;
   border-radius: 20px;
   border: 1.5px solid #d7e6fc;
   white-space: nowrap;
   padding: 9px 25px;
   transition: 0.35s;
 }

 .customBtn:hover {
   cursor: pointer;
   background-color: #f8faff;
 }

 .labell {
   font-family: serif;
   font-weight: normal;
 }

 .icon {
   display: inline-block;
   vertical-align: middle;
   width: 25px;
   height: 25px;
 }

 .buttonText {
   display: inline-block;
   vertical-align: middle;
   margin-left: 10px;
   font-size: 14px;
   font-weight: bold;
   font-family: "Roboto", sans-serif;
 }

 .logo {
   cursor: pointer;
   width: 100px;
   height: 100px;
 }

 .formtitle {
   margin-top: 20px;
   font-size: xx-large;
 }

 .intro {
   justify-content: center;
   align-items: center;
 }

 .login {
   color: #000000;
 }



 .loader {
   width: 60px;
   height: 60px;
   display: inline-block;
   position: relative;
 }

 .loader::after,
 .loader::before {
   content: '';
   box-sizing: border-box;
   width: 60px;
   height: 60px;
   border-radius: 50%;
   border: 3px solid #ffcc44;
   position: absolute;
   left: 0;
   top: 0;
   animation: animloader 1s linear infinite;
 }

 .loader::after {
   animation-delay: 0s;
 }

 @keyframes animloader {
   0% {
     transform: scale(0);
     opacity: 1;
   }

   100% {
     transform: scale(1);
     opacity: 0;
   }
 }

 .loginError {
   text-decoration: underline;
   cursor: pointer;
 }