@media (max-width: 768px) {
    .tableBox {
        padding: 20px 0px !important;
    }

    .overflow {
        overflow: auto !important;
    }

    .inputIcon {
        right: 10px;
        margin-left: 10px;
    }
}

.overflow {
    overflow-x: auto !important;
    height: 60%;
}

.sortBox {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-right: 10px;
}

.deleteBox {
    position: absolute;
    top: 0%;
    left: 0%;
    margin-left: 30px;
}

.loaderBox {
    position: absolute;
    top: 0%;
    left: 0%;
    margin-left: 120px;
    margin-top: 5px;
}

.sort {
    cursor: pointer;
}

.sortings {
    cursor: pointer;
}

.inputGroup {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

.formControl {
    border-radius: 30px !important;
    height: 50px;
    text-align: center;
    width: 100%;
    padding: 0px 20px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
    text-align: center;
}

.tableBox {
    padding: 20px 30px;
}

.AddBusinessIcon {
    background-color: #FFCC34 !important;
}

.AddBusinessIcon:hover {
    background-color: #fdc622 !important;
}

.checkBox {
    border: 1px solid #000000 !important;
}

.checkBox:focus {
    box-shadow: none !important;
    border: 1px solid #000000 !important;
}

.checkBox:checked {
    background-color: #FFCC44 !important;
    border: 1px solid #FFCC44 !important;
}

.loader {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loader::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}