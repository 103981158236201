.container {
    top: 0%;
    left: 0%;
    transform: translateY(-200px);
}

.searchBox {
    background-color: #2a2a2a;
    border-radius: 15px;
    padding: 5px 15px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.recherche {
    color: white;
    font-size: 27px;
}


.label {
    color: white;
    font-size: 20px;
}

.cardDisplay {
    height: 48dvh;
    overflow-x: auto;
    box-shadow: inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC;
}


.card {
    width: 300px !important;
    justify-content: center;
}

.cardCol {
    width: 300px !important;
}

.cardColResp {
    width: 150px !important;
}

.cardContainer {
    height: 48dvh !important;
    overflow-x: auto !important;
    box-shadow: inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC;
}


@media (max-width: 768px) {
    .card {
        width: 150px !important;
        padding-left: 0px !important;
    }

    .cardDisplay {
        height: 50dvh;
    }
}

.title{
    color: rgb(248, 248, 248);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 150px;
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
}