.card {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.img{
    height: 50px;
    width: 50px;
    background-color: #ffcd44e2;
    border-radius: 100%;
    padding: 5px;
}
.text{
    text-align: center;
    font-size: 15px;
}

.rating{
    text-align: center;
    font-size: 18px;
}