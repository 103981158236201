.page {
    margin-top: 80px;
    height: calc(100dvh - 80px);
    overflow-x: scroll;
    z-index: -999;
}

.body {
    min-height: calc(100dvh - 418px);
}

.icon {
    font-size: 100px !important;
    border-radius: 50px;
    background-color: #FFCC44;
    padding: 15px;
}

.banner {
    max-height: 40dvh;
    width: 100%;
    filter: blur(1px);
    z-index: 5;
}

.fadein {
    opacity: 1 !important;
    transition: opacity 0.5s ease;
}

.col {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.contactUs {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.311);
    width: 100%;
    height: 100%;
    text-align: center;
}

.contactUsTxt {
    color: white;
    font-weight: 700;
    font-size: 40px;
    font-family: Andale Mono, monospace;
    margin-top: 10dvh;
}

.contactUsBtn {
    background-color: #FFCC44;
    color: #ffffff;
    border-radius: 20px;
    padding: 9px 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #FFCC44;
    font-size: 15px;
    font-weight: 500;
}

.contactUsBtn:hover {
    background-color: rgba(0, 0, 0, 0.347);
    color: #FFCC44;
}

.card {
    padding: 10px;
    width: 700px !important;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.text {
    text-align: justify;
}

.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
    z-index: 9999;
}

.Scrollbutton {
    font-size: 40px !important;
    border-radius: 50%;
    background-color: #FFCC44;
    padding: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.ScrollButtonVisible {
    opacity: 1 !important;
    transition: opacity 0.5s ease;
}

.Scrollbutton:hover {
    background-color: rgb(255, 193, 21);
}

.button {
    outline: none !important;
    border: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
}