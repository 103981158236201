.logoColumn {
    max-width: 180px !important;
    height: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 150px;
    height: 150px;
    border-radius: 15px;
}

.detailsColumn {
    padding-top: 20px;
}

.addBtn {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
    width: 100%;
}

.addBtn:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

@media (max-width: 330px) {
    .formControl::placeholder {
        font-size: 14px !important;
    }
}

.inputGroup {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

.formControl {
    border-radius: 30px !important;
    height: 40px;
    width: 100%;
    padding: 0px 20px;
    padding-right: 50px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
}

/* Item Card */
.ItemCard {
    position: relative;
    border: 1px solid rgba(190, 182, 182, 0.658);
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.ItemCard:hover {
    transform: scale(1.015);
}

.ItemPhoto {
    height: 120px;
    width: 100%;
    border-radius: 5px !important;
}

.cardcontent {
    color: black;
    padding: 5px 25px !important;
}

.description {
    height: 80px;
    overflow-y: auto;
}

@media (max-width: 576px) {
    .ItemPhoto {
        height: 100px;
        width: 50%;
    }
}

.icon {
    font-size: 40px !important;
    padding: 5px;
    margin: 5px 15px 5px 2px;
    border-radius: 50%;
    background-color: #FFCC44 !important;
    color: black !important;
    transition: transform 1s ease-in-out;

}

.icon:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: #f6bc1d !important;
}

.overflow {
    height: 441px;
    overflow-x: auto;
}

.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #000000;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-left: 6px solid #FFCC44;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderBox {
    display: flex;
    justify-content: center;
    padding-top: 45px;
}