.card {
  min-height: 170px;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.myBtn {
  width: 130px !important;
  background-color: #FFCC44;
  color: #000000;
  border-radius: 20px;
  padding: 9px 25px;
  transition: 0.3s ease;
  border: 1px solid #FFCC44;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}

.myBtn:hover {
  background-color: transparent;
  color: #FFCC44;
}

.resBtn, .saveBtn {
  background-color: #000000;
  color: #ffffff;
  border-radius: 20px;
  padding: 9px 25px;
  transition: 0.3s ease;
  border: 1px solid #000000;
  font-size: 15px;
  font-weight: 500;
}

.resBtn:hover, .saveBtn:hover {
  background-color: transparent;
  color: #000000;
}

.loader {
  width: 30px;
  height: 30px;
  display: flex;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #ffcc44;
  position: absolute;
  animation: animloader 1s linear infinite;
}

.loader::after {
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.formCheckInput:checked {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #030303 !important;
}

.formCheckInput:not(:checked) {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #e1e1e1 !important;
}

.arrow {
  margin-right: 9px;
  margin-bottom: 5px;
}

.arrow:hover {
  cursor: pointer;
  border-radius: 20px;
  background-color: rgba(219, 219, 219, 0.603);
}

/*
.transitioncontainer {
  position: relative;
  transition: left 1s ease; 
}

.slidefromright {
  left: -100%;
  animation: slideInFromRight 1s forwards;
}
 
@keyframes slideInFromRight {
  from {
    left: 10%;
  }
  to {
    left: 0;
  }
} */