.xBtn {
  height: 28px;
  width: 28px !important;
  border: none;
  background-color: black;
  color: white;
  border-radius: 20px;
  transition: 0.3s ease;
  position: absolute;
  right: 0%;
  margin-top: 15px;
  margin-right: 15px;
}

.xBtn:hover {
  background-color: rgb(56, 56, 56);
}

.myBtn {
  width: 100px;
  background-color: #FFCC44;
  color: #000000;
  border-radius: 20px;
  padding: 9px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #FFCC44;
  font-size: 15px;
  font-weight: 500;
}

.myBtn:hover {
  background-color: transparent;
  color: #FFCC44;
}

.closeBtn {
  width: 100px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 20px;
  padding: 9px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #000000;
  font-size: 15px;
  font-weight: 500;
  margin-right: 5px;
}

.closeBtn:hover {
  background-color: transparent;
  color: #000000;
}

.formCheckInput:checked {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #ffcc44 !important;
}

.formCheckInput:not(:checked) {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #faecc5 !important;
}


.loader {
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  margin-top: 5px;
  right: 0%;
  bottom: 0%;
  margin-right: 240px;
  margin-bottom: 1.5rem;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #ffcc44;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1s linear infinite;
}

.loader::after {
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}