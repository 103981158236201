.icons{
    font-size: 30px !important;
    margin-bottom: 2px;
    margin-right: 10px;
}
.fb{
    color: blue;
}
.linkedin{
    color: #0077B5;
}
.pinterest{
    color: #E60023;
}
.x{
    color: black;
}
.instagram{
    color: #C91900;
}
.tiktok{
    background-color: black;
    border-radius: 100%;
    padding: 1px 4.5px;
    margin-right: 9px;
    font-size: 20px;
    color: white;
}
.formControl::placeholder{
    font-style: italic !important;
}