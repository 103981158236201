@media (max-width: 280px) {
    .container{
        width: 80% !important;
    }
}
.openHours{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    width: 500px;
}
.inline {
    display: inline-block;
}