.lock {
  height: 200px;
  width: 200px;
  position: absolute;
  top: -35%;
}

.plane {
  height: 110px;
  width: 110px;
  position: absolute;
  top: -20%;
  right: 87%;
}

@media (max-width: 512px) {
  .lock {
    top: -32%;
  }

  .plane{
    height: 80px;
    width: 80px;
    top: -25%;
    right: 79%;
  }
}

.btnClose {
  margin-right: 10px;
}

.btn {
  background-color: #ffcc44;
  color: #ffffff;
  border-radius: 20px;
  width: 80%;
  padding: 9px 25px;
  transition: background-color 0.35s ease, color 0.3s ease;
  border: 1px solid #ffcc44;
  font-size: 15px;
  font-weight: 500;
}

.btn:hover {
  background-color: transparent;
  color: #ffcc44;
}



.sendEmail {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.problem {
  margin-top:10px;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.loader {
  width: 60px;
  height: 60px;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffcc44;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1s linear infinite;
}

.loader::after {
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}