.page {
  height: 100dvh;
  overflow-x: auto !important;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.backgroundImg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -9;
}

.loginCard {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  border-radius: 20px !important;
  width: 500px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.logo {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.title {
  margin-top: 20px;
  font-size: xx-large;
}

.btn {
  background-color: #FFCC44;
  color: #ffffff;
  border-radius: 20px;
  width: 80%;
  padding: 9px 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #FFCC44;
  font-size: 15px;
  font-weight: 500;
}

.btn:hover {
  background-color: transparent;
  color: #FFCC44;
}

.customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 80%;
  border-radius: 20px;
  border: 1.5px solid #d7e6fc;
  white-space: nowrap;
  padding: 9px 25px;
}

.customBtn:hover {
  cursor: pointer;
  background-color: #F8FAFF;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
}

.buttonText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.forgot {
  text-decoration: none;
  color: #000000;
}

.signup {
  color: #000000;
}

.forgot:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hrContainer {
  position: relative;
  text-align: center;
}

.hrText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  padding: 0 10px;
}

.passwordToggle {
  position: relative;
}

.passwordToggleIcon {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  font-size: 21px;
}

.passwordToggleIcon:hover {
  text-decoration: line-through;
}

.formCheckInput:checked {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #ffcc44 !important;
}

.formCheckInput:not(:checked) {
  box-shadow: none !important;
  border: 0 solid;
  background-color: #faecc5 !important;
}