@media (max-width: 768px) {
    .sidebarToggler{
        display: none !important;
    }
    .main{
        margin-left: 0px !important;
        height: 84.35dvh;
    }
}

@media (min-width: 768px) {
    .sidebarToggler1, .respTitle{
        display: none !important;
    }
    
}

.sidebarToggler, .sidebarToggler1{
    cursor: pointer;
}
.adminBar{
    margin-top: 80px;
    height: 50px;
    background-color: black;
    color: white;
    padding: 5px 25px;
    font-size: 25px;
}

.main{
    margin-left: 250px;
    transition: ease 0.3s;
    overflow: auto;
    height: calc(100dvh - 130px);
}