.card {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding: 0px;
    min-height: 140px;
    border-radius: 5px;
    margin-top: 35px;
}

.img {
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translateY(-50%);
    height: 70px;
    width: 70px;
    border-radius: 100%;
    margin-left: calc(50% - 35px);
}

.menuicon {
    height: 15px;
    width: 15px;
    margin-bottom: 2px;
}

.noicon {
    height: 11.5px;
    width: 11.5px;
    margin-left: 10px;
    margin-top: 7px;
}

.content {
    padding: 35px 3px 0px 3px;
    height: 100%;
}


.name {
    font-weight: 500;
    transition: color 0.3s ease-in-out !important;
    color: black;
}

.name:hover {
    cursor: pointer;
    color: #ffcc43;
}

.type {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.typeicon {
    font-size: 20px !important;
    margin-bottom: 13px;
}

.ratingicon {
    font-size: 22px !important;
    margin-bottom: 5px;
    color: #f1b718;
}

.location {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.locationicon {
    font-size: 20px !important;
    margin-bottom: 5px;
    color: #f1b718;
}