.page {
    margin-top: 80px;
    height: calc(100dvh - 80px);
    overflow: auto;
    z-index: -999;
}

.body {
    min-height: calc(100dvh - 418px);
}

.pageTitleBar {
    height: 120px;
}

@media (max-width : 768px) {
    .pageTitleBar {
        height: 100px !important;
    }

    .pageTitleImage {
        clip-path: ellipse(60% 150% at 60% 50%) !important;
    }
}

@media (max-width : 520px) {
    .pageTitleImage {
        display: none !important;
    }
}

.pageTitle {
    color: white;
    position: absolute;
    top: 50%;
    left: 0%;
    margin-left: 40px;
    transform: translateY(-50%);
    font-size: 40px;
}

.pageTitleImage {
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 60%;
    clip-path: ellipse(50% 200% at 70% 50%);
}