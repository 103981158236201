.container {}

.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.break {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F8F9FA;
    border-radius: 20px;
    padding-bottom: 2.5px;
    margin: 0px 2px;
}

.breakLink {
    text-decoration: none;
    color: black;
}

.breakLink:hover {
    color: #FFCC34;
}

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F8F9FA;
    border-radius: 20px;
    padding-bottom: 2.5px;
    margin: 0px 2px;
}

.pageLink {
    text-decoration: none;
    color: black;
}

.pageLink:hover {
    color: #FFCC34;
}

.previous {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F8F9FA;
    border-radius: 20px;
    padding-bottom: 3.5px;
    margin-right: 10px;
}

.previousLink {
    text-decoration: none;
    color: black;
}

.previousLink:hover {
    color: #FFCC34;
}

.next {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F8F9FA;
    border-radius: 20px;
    padding-bottom: 3.5px;
    padding-left: 1px;
    margin-left: 10px;
}

.nextLink {
    text-decoration: none;
    color: black;
}

.nextLink:hover {
    color: #FFCC34;
}

.active {}

.activeLink {
    color: #FFCC34;
}

.disabled {}

.disabledLink {}