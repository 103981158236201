@media (max-width: 993px) {
    .normalNav {
        display: none !important;
    }
}

@media (min-width: 993px) {
    .responsiveNav {
        display: none !important;
    }

    .navbarToggler {
        display: none !important;
    }
}

.normalNav,
.responsiveNav {
    height: 80px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
}

.normalNav {
    z-index: 10 !important;
}

.responsiveNav {
    z-index: 10 !important;
}

.logo {
    background-color: white;
    width: 50px;
    height: 50px;
}

.title {
    font-size: 17px;
    text-decoration: none;
    color: black;
}

.heads {
    margin-right: 50px;
}

.offcanvas {
    width: 77% !important;
}

.navbarToggler {
    margin-top: 5px;
    margin-right: 4px;
    background-color: transparent;
    border: none !important;
    color: black !important;
    font-size: xx-large;
}

.navlinks {
    font-size: 16px !important;
    color: #2D2D2D !important;
    padding: 20px 10px;
    font-weight: 500 !important;
    font-family: system-ui;
}

.navlinks:hover {
    color: #FFCC44 !important;
}

.avatar {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    border-radius: 23px;
}

.respAvatar {
    width: 35px;
    height: 35px;
    border-radius: 20px;
}

.respWebsiteAvatar {
    width: 35px;
    height: 35px;

}

.dropdown {
    font-size: 16px !important;
    color: #2D2D2D !important;
    padding: 20px 10px;
    font-weight: 500 !important;
    font-family: system-ui;
}

.dropdown:hover {
    color: #FFCC44 !important;
}


.navItem {
    position: relative;
    display: inline-block;
}

.dropdownMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 150px;
    padding: 10px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.navItem:hover .dropdownMenu {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.dropdownLinks {
    text-decoration: none;
    font-size: 16px !important;
    color: #2D2D2D !important;
    height: 60px;
    padding-bottom: 3px;
    padding-left: 16px;
    font-weight: 400 !important;
    font-family: system-ui;
}

.dropdownLinks:hover {
    color: #FFCC44 !important;
}

.respDropdown {
    text-decoration: none;
    font-size: 15.6px !important;
    font-weight: 500 !important;
    font-family: system-ui;
    color: #2D2D2D !important;
    line-height: 2;
}

.respDropdown:hover {
    color: #FFCC44 !important;
}

.respLinks {
    font-size: 16px !important;
    color: #2D2D2D !important;
    padding-left: 15px;
    font-weight: 500 !important;
    font-family: system-ui;
}

.respLinks:hover {
    color: #FFCC44 !important;
}

.rotate-up {
    transform: rotate(180deg);
}

.userStuffs {
    margin-left: 20%;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease;
}

.authStuffs {
    margin-left: 15%;
    overflow: hidden;
    transition: height 0.3s ease;
}



.userArrow {
    transition: transform 0.4s ease;
}

.respAuth {
    text-decoration: none;
    color: #2D2D2D;
    font-weight: 500;
}

.respAuth:hover {
    color: #FFCC44;
}

.backOfficeLink {
    color: #FFCC44 !important;
    cursor: pointer;
}

.backOffice {
    margin-right: 30px;
    padding-top: 9px;
    background-color: black;
    border-radius: 40px;
    width: 120px;
    height: 60px
}

.backOfficeResp {
    font-size: 25px;
    font-weight: 500;
    margin-right: 20px;
    color: #FFCC44;
}


.prestataire {
    background-color: #FFCC44 !important;
    border-radius: 30px !important;
    font-weight: 500 !important;
    border: 1px solid #FFCC44 !important;
    padding: 5px 15px 6px 15px;
    margin-left: 30px;
    font-size: 16px !important;
    height: 50px;
}

.prestataire:hover {
    border: 1px solid #FFCC44 !important;
    background-color: white !important;
    color: #FFCC44;
}