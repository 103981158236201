.mediaCard {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.information {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.droppedImage {
    height: 180px;
    min-width: 300px;
    max-width: 300px !important;
    border-radius: 15px;
    position: relative;
}

.undoPicture {
    font-size: 25px !important;
    position: absolute;
    top: 0%;
    right: 0%;
    background-color: rgb(226, 13, 13);
    color: white;
    border-radius: 100%;
    cursor: pointer;
}

.undoPicture:hover {
    background-color: rgb(223, 46, 46);
}

.pictures {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 15px;
}

.photoAdder {
    width: 80%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed black;
    border-radius: 15px;
    flex-direction: column;
    cursor: pointer;
}

.loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loading::after,
.loading::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loading::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.savePhotos {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.savePhotos:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}