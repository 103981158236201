@media (max-width: 330px) {
    .formControl::placeholder {
        font-size: 14px !important;
    }
}

.inputGroup {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

.formControl {
    border-radius: 30px !important;
    height: 40px;
    width: 100%;
    padding: 0px 20px;
    padding-right: 50px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
}

.addCategory {
    position: relative;
}

.categoryControl {
    padding-right: 35px !important;
    position: relative;
}

.saveCategory {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 10px;
    cursor: pointer;
}



.cardList {
    min-height: 60px;
    margin-bottom: 15px;
    width: 95%;
    margin-left: 2.5%;
    padding: 15px 20px;
    margin-top: 5px;
    cursor: pointer;
    z-index: 3;
}

.selectedcardList {
    border: 1px solid #FFCC44 !important;
    box-shadow: 0 0 5px #FFCC44;
}

.nameBox {
    width: 145px !important;
    position: relative;
}

.categoryNameControl {
    padding: 1px 35px 1px 5px !important;
    position: relative;
}

.saveCategory {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 10px;
    cursor: pointer;
}

.editCategory {
    cursor: pointer;
    font-size: 20px !important;
    margin-bottom: 10px;
    margin-left: 5px;
    border-radius: 50%;
    padding: 2px;
}

.editCategory:hover {
    background-color: rgb(211, 211, 211);
    transition: 0.3s ease;
}

.deleteCategory {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 15px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
    padding: 2px;
}

.deleteCategory:hover {
    background-color: #aaaaaa4c;
    transition: 0.3s ease;
}

.categoriesBox {
    height: 50dvh !important;
    overflow-x: auto;
}

.minimizedcategoriesBox {
    height: 40dvh !important;
    overflow-x: auto;
}
.emtyBox{
    height: 10dvh !important;
    overflow-x: auto;
}

@media (max-width: 992px) {
    .categoriesBox {
        height: 30dvh !important;
        overflow-x: auto;
    }

    .minimizedcategoriesBox {
        height: 20dvh !important;
        overflow-x: auto;
    }
}