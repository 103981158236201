.page {
    margin-top: 80px;
    height: calc(100dvh - 80px);
    overflow: auto;
    z-index: -999;
}

.body {
    min-height: calc(100dvh - 418px);
}

.pageTitleBar {
    height: 120px;
}

@media (max-width : 768px) {
    .pageTitleBar {
        height: 100px !important;
    }

    .pageTitleImage {
        clip-path: ellipse(60% 150% at 60% 50%) !important;
    }

    .cardsImg {
        max-width: 70% !important;
        margin-left: 15%;
    }
}

@media (max-width : 520px) {
    .pageTitleImage {
        display: none !important;
    }

    .cardsContentTitle {
        font-size: 15px !important;
    }

    .cardsContent {
        width: 80%;
        margin-left: 10%;
    }
}

@media (max-width : 992px) {
    .aboutUsIconBox {
        min-width: 200px !important;
        max-width: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
    }

    .aboutUsContent {
        width: 100% !important;
    }
}

.pageTitle {
    color: white;
    position: absolute;
    top: 50%;
    left: 0%;
    margin-left: 40px;
    transform: translateY(-50%);
    font-size: 40px;
}

.pageTitleImage {
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 60%;
    clip-path: ellipse(50% 200% at 70% 50%);
}

.aboutUsIconBox {
    background-color: #FFCC44;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    border-radius: 50%;
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    padding: 0px 0px 20px 0px;
}

.aboutUsContent {
    width: 80%;
}

.cardsImg {
    max-width: 100%;
    width: auto;
    border-radius: 15px;
    transition: transform 0.2s ease-in-out;
}

.cardsImg:hover {
    transform: scale(1.02);

}

.hr {
    margin-top: 15px !important;
    border: 1.5px solid black;
}

.solutionTitle {
    font-weight: bolder;
}

.text {
    text-align: justify;
}