.modalContent {
    min-height: 260px !important;
}

.buttons {
    margin-right: 15px;
}

.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 15px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}

.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
    margin-left: 15px;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.close {
    background-color: black;
    color: white;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

@media(max-width : 576px) {
    .photoColumn {
        max-width: 100% !important;
        height: 180px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.photoColumn {
    max-width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo {
    width: 150px;
    height: 150px;
    border-radius: 15px;
}

.detailsColumn {
    padding-top: 20px;
}

.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #000000;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-left: 6px solid #FFCC44;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderBox {
    display: flex;
    justify-content: center;
    padding-top: 45px;
}