.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.selector {
    position: relative;
    width: 100%;
}

.selectField {
    width: 95%;
    margin-bottom: 5px;
    border-bottom: 2px solid #b6b1b1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.selectField:hover {
    border-color: #FFCC44;
}

.list {
    width: 95%;
    background: rgb(242, 242, 242);
    border-radius: 3px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    max-height: 0;
    transition: max-height 0.4s ease-in-out;
    display: block;
    z-index: 1;
}

.visible {
    max-height: 200px;
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
}

.rotateBack {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
}

.options {
    width: 100%;
    padding: 5px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;
}

.options:hover {
    background-color: #ffcd448b !important;
}


.loader {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loader::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.membershipControl {
    width: 100%;
}