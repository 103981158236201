.backgroundCards {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: 200px;
    padding-bottom: 20px;
}

.dropArea {
    margin-top: 5px;
    cursor: pointer;
    z-index: 0;
    border-radius: 10px;
    border: 2px dashed #49494866;
    height: 140px;
    position: relative;
    transition: 0.3s ease;
}

.dropArea:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.cloudUpload {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    transform: translateX(-50%);
    height: 50px !important;
    width: 50px !important;
    cursor: pointer;
}

.imgView {
    border-radius: 10px;
    margin-top: 5px;
    cursor: pointer;
    height: 140px;
    transition: 0.2s ease-in-out;
}

.imgView:hover {
    transform: scale(1.02);
}

.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
    width: 60%;
    margin-left: 20%;

}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loading::after,
.loading::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loading::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}