@media (max-width: 330px) {
    .formControl::placeholder {
        font-size: 14px !important;
    }
}

.inputGroup {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

.formControl {
    border-radius: 30px !important;
    height: 40px;
    width: 100%;
    padding: 0px 60px 0px 20px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
}

.forbidden{
    position: absolute;
    min-width: 100% !important;
    min-height: 100%;
    background-color: #e4e4e4;
    border-radius: 15px !important;
    z-index: 15;
}
.forbiddenText{
    position: absolute;
    top: 40%;
    left: 0%;
    margin-left: calc(50% - 70px);
    transform: translateY(-30%);
    font-size: 50px;
    font-weight: 400;
}

.addEstabs {
    background-color: #FFCC44 !important;
    border-radius: 30px !important;
    font-weight: 500 !important;
    border: 1px solid #FFCC44 !important;
    padding: 5px 15px 6px 15px;
    font-size: 16px !important;
    height: 39px;
}

.addEstabs:hover {
    border: 1px solid #FFCC44 !important;
    background-color: white !important;
    color: #FFCC44;
}

.estabsLogo {
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.estabsLogo:hover {
    cursor: pointer;
}

.estabsName {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: .5rem;
    transition: color 0.3s ease-in-out;
}

.estabsName:hover {
    cursor: pointer;
    color: #FFCC44;
}

.link {
    text-decoration: none;
    color: black;
}

.cardCol {
    width: 350px !important;
}

.card {
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 15px;
    border-radius: 15px !important;
    position: relative;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

@media (max-width: 576px) {
    .estabsLogo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .featured {
        font-size: 15px !important;
    }

    .delBtn {
        margin-right: 16px !important;
        font-size: 22px !important;
    }

    .editBtn {
        margin-right: 45px !important;
        margin-top: 15px;
        font-size: 19px !important;
    }

    .hideBtn {
        margin-left: 56px !important;
        font-size: 22px !important;
    }

    .menusNumberBox {
        margin-left: 20px;
        margin-top: 10px !important;
    }

    .formControl {
        padding: 0px 50px 0px 20px !important;
    }

    .inputIcon {
        right: 20px;
    }
}

.buttonsHover:hover {
    cursor: pointer;
}

.delBtn {
    top: 0%;
    right: 0%;
    margin-right: 20px;
    margin-top: 15px;
    position: absolute;
    font-size: 25px;
}

.editBtn {
    top: 0%;
    right: 0%;
    margin-right: 55px;
    margin-top: 15px;
    position: absolute;
    font-size: 23px;
}

.hideBtn {
    top: 0%;
    left: 0%;
    margin-left: 60px;
    margin-top: 15px;
    position: absolute;
    font-size: 30px;
}

.menusNumberBox {
    position: absolute;
    top: 0%;
    left: 0%;
    margin-left: 20px;
    margin-top: 12px;
}

.menusNumber {
    font-size: 20px;
}

.featuredCol {
    height: 60px;
    padding-top: 13px;
}

.featured {
    font-size: 20px;
}

.loader {
    width: 90px;
    height: 90px;
    display: flex;
    position: absolute;
    top: 0%;
    left: 0%;
    margin-left: calc( 50% - 45px);
    margin-top: 2%;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 5px solid #ffcc44;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1.5s linear infinite;
}

.loader::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}