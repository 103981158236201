@media (max-width: 768px) {
    .tableBox{
        padding : 20px 0px !important;
    }
    .overflow{
        overflow: auto !important;
    }
    .inputIcon{
        right: 10px;
        margin-left: 10px;
    }
}
.overflow{
    overflow-x: auto !important;
    height: 60%;
}
.filter{
    cursor: pointer;
}
.sort{
    cursor: pointer;
}
.filterings{
    cursor: pointer;
}
.sortings{
    cursor: pointer;
}
.inputGroup{
    position: relative;
}
.inputIcon{
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}
.formControl{
    border-radius: 30px !important;
    height: 50px;
    text-align: center;
    width: 100%;
    padding: 0px 20px;
}
.formControl:focus {
    box-shadow: none;
    outline: none; 
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
    text-align: center;
}
.tableBox{
    padding : 20px 30px;
}

.avatar{
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.LockResetIcon{
    background-color: #FFCC34 !important;
}
.LockResetIcon:hover{
    background-color: #fdc622 !important;
}


.activeStatus{
    margin: 13px 0px;
    color: rgb(1, 175, 18);
    font-weight: 500;
}
.outdatedStatus{
    margin: 13px 0px;
    color: rgb(194, 0, 0);
    font-weight: 500;
}