@media (min-width: 768px) {
    .head {
        display: none !important;
    }
}

@media (max-width: 768px) {}

.mainContainer {
    z-index: 20 !important;
}

.sidebar {
    width: 23vw;
    overflow: hidden !important;
}

.overflow {
    height: calc(100dvh - 130px);
    overflow: auto !important;
}

.head {
    font-size: 20px !important;
    height: 10dvh;
    overflow: hidden !important;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logo {
    width: 50px;
    height: 50px;
}

.title {
    display: flex;
    align-items: center;
}

.notificationIcon {
    background-color: #FFCC44;
    color: black;
    width: auto;
    height: auto;
    min-width: 22px !important;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    padding: 3px 5px 4px 5px;
    font-size: 10px;
}

.notificationIconGeneral {
    background-color: red;
    min-width: 10px !important;
    min-height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0%;
    margin-right: 20px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    padding: 3px 5px 4px 5px;
    margin-top: 2.5px;
}