.inputGroup {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

.formControl {
    border-radius: 30px !important;
    height: 50px;
    text-align: center;
    width: 100%;
    padding: 0px 60px 0px 20px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 18px;
    color: #aaa;
    font-style: italic;
    text-align: center;
}

.filterBox {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-right: 100px;
}

.sortBox {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-right: 10px;
}

.filter {
    cursor: pointer;
}

.sort {
    cursor: pointer;
}

.filterings {
    cursor: pointer;
}

.sortings {
    cursor: pointer;
}

.EditIcon {
    background-color: #FFCC34 !important;
}

.EditIcon:hover {
    background-color: #fdc622 !important;
}

.visibility {
    margin-top: 5px;
    border-radius: 50% !important;
    border: 0px;
    background-color: transparent;
    transition: 0.3s ease;
}

.visibility:hover {
    background-color: #edededb1 !important;

}

.estbname {
    text-decoration: none;
    color: black;
}

.estbname:hover {
    cursor: pointer;
    color: #FFCC34;
}