.navbar {
    background-color: black;
    height: 50px;
    padding: 8px 0px;
    z-index: 9 !important;
    margin-top: 80px;
}

.navList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navListLinks {
    display: inline;
}

.navLinks {
    display: inline-block;
    color: white !important;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    position: relative;
    transition: color 0.4s ease-in-out, opacity 0.6s ease-in-out;
    margin: 0px 12px 0px 12px;
    padding-bottom: 5px;
}

.navLinks:hover {
    color: white;
}

.navLinks::after {
    content: '';
    height: 2px;
    width: 95%;
    background: white;
    position: absolute;
    right: 3%;
    bottom: 3px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.navLinks:hover::after,
.navLinks.active::after {
    opacity: 1;
}

@media (max-width: 508px) {
    .navLinks {
        font-size: 18px !important;
    }
}

@media (max-width: 465px) {
    .navLinks {
        font-size: 13px !important;
    }
}

@media (max-width: 372px) {
    .navLinks {
        font-size: 13px !important;
        margin: 0px 2px 0px 5px !important;
    }
}