.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    border-top: 7px solid black;
    border-right: 7px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left: 7px solid #FFCC44;
    border-bottom: 7px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 