.page {
    margin-top: 80px;
    height: calc(100dvh - 80px);
    overflow: auto;
    z-index: -999;
}

.body {
    min-height: calc(100dvh - 418px);
}

.pageTitleBar {
    height: 120px;
}

@media (max-width : 768px) {
    .pageTitleBar {
        height: 100px !important;
    }

    .pageTitleImage {
        clip-path: ellipse(60% 150% at 60% 50%) !important;
    }
}

@media (max-width : 520px) {
    .pageTitleImage {
        display: none !important;
    }
}

.pageTitle {
    color: white;
    position: absolute;
    top: 50%;
    left: 0%;
    margin-left: 40px;
    transform: translateY(-50%);
    font-size: 40px;
}

.pageTitleImage {
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 60%;
    clip-path: ellipse(50% 200% at 70% 50%);
}

.iFrameContainer {
    width: 100%;
    height: 500px;
    padding: 20px;
}

.iFrame {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.send {
    background-color: #FFCC44 !important;
    border-radius: 30px !important;
    font-weight: 500 !important;
    border: 1px solid #FFCC44 !important;
    padding: 5px 15px 6px 15px;
    font-size: 16px !important;
    height: 39px;
}

.send:hover {
    border: 1px solid #FFCC44 !important;
    background-color: white !important;
    color: #FFCC44;
}

.loader {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loader::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}