.addMenu {
    width: 80%;
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
    margin: 0px !important;
}

.addMenu:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.menusBox {
    height: 50dvh !important;
    overflow-x: auto;
}

.minimizedMenusBox {
    height: 25dvh !important;
    overflow-x: auto;
}

@media (max-width: 992px) {
    .menusBox {
        height: 25dvh !important;
        overflow-x: auto;
    }

    .minimizedMenusBox {
        height: 15dvh !important;
        overflow-x: auto;
    }
}

.cardList {
    min-height: 60px;
    margin-bottom: 15px;
    padding: 15px 20px;
    margin-top: 5px;
    cursor: pointer;
}

.selectedcardList {
    border: 1px solid #FFCC44 !important;
    box-shadow: 0 0 5px #FFCC44;
}

.nameBox {
    width: 150px !important;
    position: relative;
}

.nameControl {
    padding: 1px 35px 1px 5px !important;
    position: relative;
}

.saveMenu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 10px;
    cursor: pointer;
}

.editName {
    cursor: pointer;
    font-size: 20px !important;
    margin-bottom: 10px;
    margin-left: 5px;
    border-radius: 50%;
    padding: 2px;
}

.editName:hover {
    background-color: rgb(211, 211, 211);
    transition: 0.3s ease;
}

.deleteMenu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
}

.deleteMenu:hover {
    background-color: #aaaaaa4c;
    transition: 0.3s ease;
}