.card{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    height: 80px !important;
    width: 100%;
    min-width: fit-content !important;
    background-color: #fbc633ae;
}

.icon{
    font-size: 30px !important;
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: -2.5px;
}

.cardTitle{
    display: block;
}
@media (max-width: 640px) { 
    .cardTitle{
        display: none;
    }
    .card{
        height: 60px !important;
        width: 90%;
        min-width: 100px !important;
        background-color: #fbc633ae;
    }
 }

 @media (max-width: 470px) { 
    .cardTitle{
        display: block;
    }
    .card{
        height: 80px !important;
        width: 100%;
        min-width: 140px !important;
        background-color: #fbc633ae;
    }
 }