.page {
    height: calc(100dvh - 80px);
    margin-top: 80px !important;
    overflow-x:auto !important ;
}

.body {
    min-height: calc(100dvh - 418px);
    overflow-x: hidden;
}

.img {
    height: 350px;
}

.profile {
    left: 0;
    top: 0;
    margin-top: -50px;
    margin-left: 100px;
    border-radius: 150px !important;
    border: 5px solid white;
    background-color: white;
}

.pic {
    height: 150px;
    width: 150px;
    border-radius: 150px !important;
}

@media (max-width: 996px) {
    .img {
        height: 250px;
    }

    .profile {
        left: 50% !important;
        margin-left: -70px !important;
    }

    .pic {
        height: 140px !important;
        width: 140px !important;
    }

    .col {
        margin-top: 100px !important;
    }

    .estName,
    .estRating,
    .estType {
        margin-left: 0px !important;
    }

    .estName,
    .info,
    .estType {
        text-align: center;
    }
}

.estName {
    margin-left: 260px;
    font-size: 30px;
    word-wrap: break-word;
}

.estStatus {
    font-weight: 640;
}

.estRating {
    margin-left: 260px;
    font-size: 18px;
    color: #ffc118;
}

.estType {
    margin-left: 260px;
    font-weight: 370;
}

.icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #FFCC44;
    padding: 8px;
}

.table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
}

.contactIcon {
    font-size: 26px !important;
    margin: 5px;
}

.contactIcontiktok {
    font-size: 15px !important;
    margin: 5px;
    padding: 2px 4px;
    background-color: black;
    border-radius: 100%;
    color: white;
}

.contactInfo {
    font-weight: 460;
    color: black;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.contactInfo:hover {
    cursor: pointer;
    color: #FFCC44;
}

.col {
    word-wrap: break-word;
}

.card {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.show {
    height: auto !important;
    transition: height 1s ease !important;
}

.hide {
    /* min-height:  100px ;
    transition: min-height 1s ease-in-out !important; */
}

.arrow {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
}

.arrowBack {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
}

.displayInfo {
    display: none !important;
}