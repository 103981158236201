.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 15px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}

.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}
