.logo {
    height: 50px;
    width: 50px;
}

.logoText {
    margin-left: 10px;
    font-size: 20px;
}

.links {
    text-decoration: none;
    color: black;
}

.socials {
    font-size: 30px;
}

.fb {
    color: #0866FF;
}

.inst {
    filter: sepia(100%) saturate(10000%) hue-rotate(30deg);
}

.tw {
    color: black;
}

.ytb {
    color: red;
}
.hr{
    color: rgb(173, 173, 173) !important;
}