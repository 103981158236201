@media (max-width: 958px) {
    .highlights{
        padding: 0% 15% !important;
    }
}
@media (max-width: 768px) {
    .highlights{
        padding: 0% 10% !important;
    }
}
@media (max-width: 695px) {
    .highlights{
        padding: 0% 5% !important;
    }
}
@media (max-width: 600px) {
    .highlights{
        padding: 0% 0% !important;
    }
}
@media (max-width: 576px) {
    .highlights{
        padding: 0% 5% !important;
    }
    .box {
        padding: 5px !important;
        margin-bottom: 30px !important;
        min-width: 90px !important;
        max-width: 90px !important;
        border-radius: 20px !important;
    }
    .icon {
        width: 50px !important;
        height: 50px !important;
    }
    .title{
        font-size: 11px;
    }
}
@media (max-width: 280px) {
    .box {
        min-width: 75px !important;
        max-width: 75px;
    }
}


.highlights{
    padding: 0% 20%;
    display: flex;
    justify-content: center;
}
.box {
    padding: 15px;
    margin-bottom: 30px;
    min-width: 140px;
    max-width: 140px;
    min-height: 100px;
    border-radius: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
    cursor: pointer;
}
.checked{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.icon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFCC44;
    padding: 15px;
}