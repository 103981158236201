.loadingBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #000000;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-left: 6px solid #FFCC44;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loading::after,
.loading::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loading::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.cardsBox {
    margin: 15px 40px;
    max-height: 184px !important;
    overflow-x: auto;
    padding-bottom: 8px;
    width: fit-content;
}

.card {
    border: 1px solid gray;
    border-radius: 10px;
    height: 50px;
    min-width: 180px !important;
    max-width: 200px !important;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.cardName {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    margin-left: 10px;
    border-radius: 50%;
    padding: 2px;
}

.cardDel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
}


.addType,
.addHighlight {
    position: relative;
}

.typeControl {
    padding-right: 35px !important;
    position: relative;
}

.highlightControl {
    padding-right: 35px !important;
}

.saveType,
.saveHighlight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    margin-right: 10px;
    cursor: pointer;
}

.photoColumn {
    max-width: 180px;
    display: flex;
    justify-content: center;
}

.photo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.box {
    padding: 15px;
    margin-bottom: 30px;
    min-width: 140px !important;
    max-width: 140px !important;
    min-height: 192px !important;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
    margin: 15px 7.5px;
}

.deletingIcon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFCC44;
    padding: 15px;
    filter: blur(1px);
}

.deletingTitle {
    filter: blur(1px);
}

.icon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFCC44;
    padding: 15px;
}

.deleteIcon {
    position: absolute;
    top: 0%;
    right: 0%;
    border-radius: 50%;
    cursor: pointer;
}

.deleteIcon:hover {
    background-color: #d8d8d86c;
}

.confirmBox {
    border-radius: 20px;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.703);
    display: flex;
}

.confirmTxt {
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
}

.cancelTxt {
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 90px;
}