.card {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

@media (max-width: 576px) {
    .separator {
        border-right: none !important;
    }
}

.menuName {
    height: 30px;
    font-weight: 460;
    color: black;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.menuName:hover {
    cursor: pointer;
    color: #FFCC44;
    border-bottom: 2px solid #FFCC44;
}