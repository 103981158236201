body {
  margin: 0 !important;
  overflow: hidden;
}

.active {
  color: #FFCC44 !important;
}

.app-page {
  margin-top: 80px;
  height: calc(100dvh - 80px);
  overflow: auto;
  z-index: -999;
}

.clientPage {
  margin-top: 130px;
  height: calc(100dvh - 130px);
  overflow: auto;
  z-index: -999;
}

.adminPage {
  margin-top: 80px;
  height: calc(100dvh - 80px);
  overflow: auto;
  z-index: -999;
}

.txtSuccess {
  color: rgb(1, 175, 18) !important;
}

.txtDanger {
  color: rgb(194, 0, 0) !important;
}

.txtWarning {
  color: rgb(255, 157, 0) !important;
}


::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #FFCC44;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e1b53c;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.is-invalid~.invalid-feedback {
  display: none;
}

.form-control:focus {
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.5) !important;
  border: 1px solid #000000 !important;
}

html {
  scroll-behavior: smooth !important;
}

.fadeInUpScroll {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}