.mediaCard {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.information {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.img {
    height: 150px;
    width: 150px;
    border-radius: 75px;
}

.loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loading::after,
.loading::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loading::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.saveLogo {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.saveLogo:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}