.modalContent {
    min-height: 262px;
}

.buttons{
    margin-right: 15px;
}

.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 15px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}

.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.disabledSave {
    background-color: white;
    color: #FFCC44;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid #FFCC44;
    transition: 0.3s ease;
    font-weight: 500;
}


.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.tabs{
    font-size: 20px !important;
    margin-right: 10px;
    

}
.enabledTab{
    cursor: pointer;
}
.disabledTab{
    cursor:default !important;
}

.loading {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loading::after,
.loading::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loading::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}