.signup {
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  overflow-x: auto !important;
}

.backgroundImg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -9;
}

@media (max-width: 993px) {
  .signup {
    align-items: normal !important;
    height: 100dvh;
    overflow-x: auto !important;
  }

  .title {
    margin-top: 4%;
    font-size: 20px;
  }

  .bigtitle {
    font-size: 30px !important;
    margin-bottom: 4%;
  }

  .card {
    margin-bottom: 50px;
  }
}

.card {
  width: 500px;
  border-radius: 20px !important;
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.intro {
  justify-content: center;
  align-items: center;
}

.title {
  color: #F03D4F;
  font-size: 20px;
  -webkit-text-stroke: 1px #e53749;
  text-shadow: 0px 4px 4px #ffffff;
}

.bigtitle {
  font-size: 64px;
  color: #000000;
  -webkit-text-stroke: 1px #282828;
  text-shadow: 0px 4px 4px #ffffff;
}