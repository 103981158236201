.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.resetBtn {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.resetBtn:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.myLocation {
    position: relative;
}
.location{
    padding-right: 55px !important;
}

.myLocationIcon {
    position: absolute;
    top: 44%;
    right: 0%;
    margin-right: 25px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 5px;
    font-size: 21px;
}

.myLocationIcon:hover {
    text-decoration: line-through;
}

.tagBox {
    display: flex;
    padding: 4px 5px 4px 5px;
    border: 1px solid #DEE2E6;
    border-radius: 6px;
    margin: 0.5px 0px;
    height: 38px;
}

.tagBoxInvalid {
    display: flex;
    padding: 4px 5px 4px 5px;
    border: 1px solid #ff1515;
    border-radius: 6px;
    margin: 0.5px 0px;
    height: 38px;
}

.boxTags {
    list-style: none;
    background: #f2f2f2;
    padding: 0px 25px 3px 10px;
    border-radius: 5px;
    border: 1px solid #DEE2E6;
    position: relative;
    margin-right: 6px;
}

.undoTag {
    height: 18px;
    width: 18px;
    font-size: 12px;
    background: #dfdfdf;
    border-radius: 50%;
    cursor: pointer;
    color: #808000;
    padding: 0px 3px 0px 3px;
    margin-left: 5px;
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 4px;
    margin-right: 3px;
}

.boxInput {
    flex: 1;
    outline: none;
    pad: 5px;
    font-size: 16px;
    border: none;
}

.card{
    cursor: pointer;
    transition: 0.3s ease;
    min-width: 150px !important;
}

.selectedCard{
    cursor: pointer;
    min-width: 150px !important;
    border: 1px solid #FFCC44 !important;
    box-shadow: 0 0 5px #FFCC44;
    transition: 0.3s ease;
}