/* Keyframes */
@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Animation */
.zoomIn {
    animation: zoomIn 0.3s ease-in-out;
}

/* Container */
.container {
    height: 83dvh;
    position: relative;
    padding-left: 250px !important;
    transition: padding-left 0.3s ease;
}

.containerCollapse {
    padding-left: 20px !important;
    padding-right: 20px !important;
    transition: padding-left 0.3s ease;
    z-index: 10 !important;
}

.title {
    position: absolute;
    left: 0%;
    top: 0%;
    margin-left: 50px;
    margin-top: 13px;
}

/* Categories */
.categories {
    overflow-x: auto;
    height: 83dvh;
}

.cat {
    text-align: start;
    margin-left: 20px;
    padding: 5px 5px 5px 20px;
}

.cat:hover {
    color: #FFCC34;
    font-weight: 600;
    cursor: pointer;
}

.activeCat {
    color: #FFCC34;
    font-weight: 600;
}


/* Carousel */
.carousel {
    height: 78dvh;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.col {
    height: 130px;
}

/* Item Card */
.ItemCard {
    border: 1px solid rgba(190, 182, 182, 0.658);
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.ItemCard:hover {
    transform: scale(1.025);
}

.ItemPhoto {
    height: 100px;
    width: 100%;
    border-radius: 5px !important;
}

.photoDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardcontent {
    color: black;
    padding: 5px 25px !important;
}

.description {
    height: 70px;
    overflow-y: auto;
}

.next,
.prev {
    width: 25px !important;
}

/* Media Queries */
@media (max-width: 965px) {

    .containerCollapse {
        padding: 20px 40px 10px 40px !important;
    }

    .container {
        height: 85dvh;
    }

    .categories {
        height: 83dvh;
    }

    .carouselCollapse {
        height: 75dvh;
    }
}

@media (max-width: 768px) {

    .container {
        height: 85dvh;
        position: relative;
        padding: 20px 40px 10px 40px !important;
        transition: padding-left 0.4s ease;
        z-index: 10 !important;
    }

    .containerCollapse {
        padding: 20px 40px 10px 40px !important;
    }

    .showCat {
        width: 250px !important;
        position: absolute;
        background-color: white;
        left: 0%;
        transition: width 0.4s ease;
        z-index: 15 !important;
    }

    .categories {
        height: 83dvh;
    }

    .inputIcon {
        right: 10px;
        margin-left: 10px;
    }

    .carousel {
        height: 75dvh;
    }

    .ItemPhoto:hover {
        position: absolute;
        transform: scale(1.09);
        top: 0%;
        left: 5%;
        margin-top: 20px;
        width: 50%;
        height: 70%;
        border-radius: 5px !important;
    }
}

@media (max-width: 576px) {
    .ItemPhoto {
        height: auto;
        width: 100%;
    }
}

@media (max-width: 460px) {
    .container {
        padding: 20px !important;
    }
}

/* Icon */
.icon {
    font-size: 40px !important;
    padding: 5px;
}

.icon:hover {
    background-color: rgba(196, 196, 196, 0.399);
    border-radius: 100%;
    cursor: pointer;
}

/* Category */
.hideCat {
    width: 0px !important;
    transition: width 0.4s ease;
    position: absolute;
    left: -200%;
    top: 0%;
    border-right: 1px solid rgba(102, 102, 102, 0.278);
}

.showCat {
    width: 255px !important;
    position: absolute;
    top: 0%;
    left: 0%;
    transition: width 0.4s ease;
    border-right: 1px solid rgba(102, 102, 102, 0.278);
    box-shadow: 5px 0px 5px rgba(102, 102, 102, 0.278);
}

/* Form Control */
.inputGroup {
    position: relative;
    padding: 15px 8px;
}

.formControl {
    border-radius: 30px !important;
    height: 30px;
    text-align: start;
    width: 100%;
    padding: 4px 30px 5px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.formControl:focus {
    box-shadow: none;
    outline: none;
    border: none !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.formControl-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.formControl::placeholder {
    font-size: 15px;
    color: #aaa;
    font-style: italic;
    text-align: start;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    padding: 5px;
    color: #FFCC34;
}

/* Buttons */
.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 5px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}

.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
    position: absolute;
    bottom: 0%;
    right: 0%;
    margin-bottom: 10px;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.caticon {
    font-size: 28px !important;
    position: absolute;
    left: 0%;
    top: 0%;
    margin-top: 6px;
    margin-left: 25px;
}