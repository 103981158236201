.modalContent {
    min-height: 263px;
}

.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 15px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}


.status {
    font-size: 16px;
}


.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.disabledSave {
    background-color: white !important;
    color: #FFCC44;
    border: 1px solid #FFCC44 !important;
    cursor: default !important;
}

.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.selector {
    position: relative;
    width: 95%;
}

.selectField {
    width: 95%;
    margin-bottom: 5px;
    border-bottom: 2px solid #b6b1b1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.selectField:hover {
    border-color: #FFCC44;
}

.list {
    width: 95%;
    background: rgb(242, 242, 242);
    border-radius: 3px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    display: block;
    z-index: 1;
}

.visible {
    max-height: 200px;
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
}

.rotateBack {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
}

.options {
    width: 100%;
    padding: 5px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;
}

.options:hover {
    background-color: #ffcd448b !important;
}



.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #000000;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-left: 6px solid #FFCC44;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderBox {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.checkBox {
    border: 1px solid #000000 !important;
}

.checkBox:focus {
    box-shadow: none !important;
    border: 1px solid #000000 !important;
}
.checkBox:checked{
    background-color: #FFCC44 !important;
    border: 1px solid #FFCC44 !important;
}

.radio {
    border: 1px solid #000000 !important;
}

.radio:focus {
    box-shadow: 0 0 0 0.9px #FFCC44 !important;
    border: 1px solid #FFCC44 !important;
}
.radio:checked{
    background-color: #FFCC44 !important;
    border: 1px solid #FFCC44 !important;
}