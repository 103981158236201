.modalContent {
    /* min-height: 262px; */
}

.buttons {
    margin-right: 15px;
}

.closeBtn {
    height: 28px;
    width: 28px !important;
    border: none;
    background-color: black;
    color: white;
    border-radius: 20px;
    transition: 0.3s ease;
    position: absolute;
    right: 0%;
    margin-top: 15px;
    margin-right: 15px;
}

.closeBtn:hover {
    background-color: rgb(56, 56, 56);
}

.save {
    background-color: #FFCC44;
    color: black;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.save:hover {
    background-color: white;
    color: #FFCC44;
    border: 1px solid #FFCC44;
}

.close {
    background-color: black;
    color: white;
    margin-right: 15px;
    border-radius: 25px;
    padding: 5px 15px 7px 15px;
    border: 1px solid transparent;
    transition: 0.3s ease;
    font-weight: 500;
}

.close:hover {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid black;
}

.passwordToggle {
    position: relative;
}

.passwordToggleIcon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 5px;
    font-size: 21px;
}

.passwordToggleIcon:hover {
    text-decoration: line-through;
}

.photoCard {
    border-radius: 10px !important;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.img {
    height: 160px;
    width: 160px;
    border-radius: 80px;
}

.upload {
    cursor: pointer;
    transition: 0.3s ease;
    color: #FFCC44;
}

.infoCard {
    min-height: 300px !important;
    border-radius: 10px !important;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.icon {
    font-size: 30px;
    color: #49494891;
}



.cardheader {
    padding: 1rem 1.35rem;
    font-weight: bold;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.loader {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #fd7a09;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s linear infinite;
}

.loader::after {
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}