.card {
    min-height: 170px;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.delBtn {
    background-color: #FFCC44;
    color: #000000;
    border-radius: 20px;
    padding: 9px 25px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid #FFCC44;
    font-size: 15px;
    font-weight: 500;
}

.delBtn:hover {
    background-color: transparent;
    color: #FFCC44;
}